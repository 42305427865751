import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, Container, Row, Col, Card, Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import axios from 'axios';
import StarRating from '../components/StarRating';

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState({ movies: [], shows: [] });
  const [genres, setGenres] = useState({ movie: {}, tv: {} });
  const [selectedGenre, setSelectedGenre] = useState({ movie: 'All', tv: 'All' });
  const [selectedDecade, setSelectedDecade] = useState({ movie: 'All', tv: 'All' });
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('movies');
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [ratings, setRatings] = useState({});
  const [showBackToTop, setShowBackToTop] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const removeDuplicates = (items) => {
    const uniqueItems = items.reduce((acc, currentItem) => {
      if (!acc.some(item => item.id === currentItem.id)) {
        acc.push(currentItem);
      }
      return acc;
    }, []);
    return uniqueItems;
  };

  const fetchFavorites = useCallback(async () => {
    if (!user) return;

    try {
      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      if (userFavoritesDoc.exists()) {
        const items = userFavoritesDoc.data().items || [];
        const movies = removeDuplicates(items.filter((item) => item.media_type === 'movie').reverse());
        const shows = removeDuplicates(items.filter((item) => item.media_type === 'tv').reverse());
        setFavorites({ movies, shows });

        // Initialize ratings state
        const initialRatings = items.reduce((acc, item) => {
          if (item.rating) acc[item.id] = item.rating;
          return acc;
        }, {});
        setRatings(initialRatings);
      } else {
        setFavorites({ movies: [], shows: [] });
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  }, [user]);

  useEffect(() => {
    fetchFavorites();
  }, [user, fetchFavorites]);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const movieGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/movie/list?api_key=${API_KEY}`
        );
        const tvGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/tv/list?api_key=${API_KEY}`
        );

        setGenres({
          movie: movieGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
          tv: tvGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
        });
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleRemove = async (item) => {
    try {
      const userFavoritesRef = doc(db, 'favorites', user.uid);
      await updateDoc(userFavoritesRef, {
        items: arrayRemove(item),
      });

      setFavorites((prevFavorites) => ({
        movies: prevFavorites.movies.filter((fav) => fav.id !== item.id),
        shows: prevFavorites.shows.filter((fav) => fav.id !== item.id),
      }));

      // Re-fetch favorites to ensure UI consistency
      await fetchFavorites();
    } catch (error) {
      console.error('Error removing item from favorites:', error);
    }
  };

  const handleRatingChange = async (item, rating) => {
    try {
      const updatedItem = { ...item, rating };
      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      if (userFavoritesDoc.exists()) {
        const items = userFavoritesDoc.data().items || [];
        const updatedItems = items.map((favItem) =>
          favItem.id === item.id && favItem.media_type === item.media_type ? updatedItem : favItem
        );
        await updateDoc(userFavoritesRef, { items: updatedItems });

        setRatings((prevRatings) => ({
          ...prevRatings,
          [item.id]: rating,
        }));
      }
    } catch (error) {
      console.error('Error updating rating:', error);
    }
  };

  const renderGenresButtons = (type) => {
    const uniqueGenres = Array.from(
      new Set(
        favorites[type === 'movie' ? 'movies' : 'shows']
          .flatMap((item) => item.genre_ids.map((id) => genres[type][id]))
          .filter((genre) => genre !== undefined)
      )
    );

    return (
      <div className="d-flex flex-nowrap overflow-auto mb-3">
        <Button
          variant={selectedGenre[type] === 'All' ? 'primary' : 'light'}
          className="me-2"
          onClick={() => setSelectedGenre((prev) => ({ ...prev, [type]: 'All' }))}
        >
          All
        </Button>
        {uniqueGenres.map((genre, index) => (
          <Button
            key={index}
            variant={selectedGenre[type] === genre ? 'primary' : 'light'}
            className="me-2"
            onClick={() =>
              setSelectedGenre((prev) => ({
                ...prev,
                [type]: prev[type] === genre ? 'All' : genre,
              }))
            }
          >
            {genre}
          </Button>
        ))}
      </div>
    );
  };

  const renderDecadeDropdown = (type) => {
    const items = favorites[type === 'movie' ? 'movies' : 'shows'];
    const years = items
      .map((item) => {
        const year = item.release_date
          ? item.release_date.split('-')[0]
          : item.first_air_date
          ? item.first_air_date.split('-')[0]
          : null;
        return parseInt(year, 10);
      })
      .filter((year) => !isNaN(year));

    const uniqueDecades = Array.from(new Set(years.map((year) => Math.floor(year / 10) * 10)));

    return (
      <DropdownButton
        id={`dropdown-decade-${type}`}
        title={`Years: ${selectedDecade[type] === 'All' ? 'All' : `${selectedDecade[type]}-${selectedDecade[type] + 9}`}`}
        variant="secondary"
        onSelect={(e) =>
          setSelectedDecade((prev) => ({
            ...prev,
            [type]: e === 'All' ? 'All' : parseInt(e, 10),
          }))
        }
      >
        <Dropdown.Item eventKey="All" active={selectedDecade[type] === 'All'}>
          All
        </Dropdown.Item>
        {uniqueDecades.sort((a, b) => b - a).map((decade, index) => (
          <Dropdown.Item
            key={index}
            eventKey={decade}
            active={selectedDecade[type] === decade}
          >
            {`${decade}-${decade + 9}`}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };

  const renderItems = (items, type) => {
    return items.map((item) => {
      const imgSrc = item.poster_path
        ? `https://image.tmdb.org/t/p/w500${item.poster_path}`
        : 'https://via.placeholder.com/150';
      const releaseYear = item.release_date
        ? item.release_date.split('-')[0]
        : item.first_air_date
        ? item.first_air_date.split('-')[0]
        : 'Unknown';

      const briefDescription = item.overview
        ? item.overview.substring(0, 100) + '...'
        : 'No description available';
      const isExpanded = expandedDescriptions[item.id];

      return (
        <Col key={item.id} lg={3} md={6} sm={12} className="mb-4">
          <Card className="h-100">
            <Card.Img
              variant="top"
              src={imgSrc}
              alt={`${item.title || item.name} Poster`}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain', // Ensures the whole image is visible without cropping
                borderRadius: '0.25rem',
                position: 'relative',
              }}
            />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{item.title || item.name}</Card.Title>
              <Card.Text>Rating: {item.vote_average}</Card.Text>
              <Card.Text>Year: {releaseYear}</Card.Text>
              <StarRating
                rating={ratings[item.id] || 0}
                onRatingChange={(newRating) => handleRatingChange(item, newRating)}
              />
              <div
                onClick={() =>
                  setExpandedDescriptions((prev) => ({
                    ...prev,
                    [item.id]: !prev[item.id],
                  }))
                }
                style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#f9f9f9',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
              >
                <p className="card-text">
                  {isExpanded ? item.overview : briefDescription}
                </p>
                <Button
                  variant="link"
                  style={{ padding: '0', textDecoration: 'none', color: '#007bff' }}
                >
                  {isExpanded ? 'View Less' : 'View More'}
                </Button>
              </div>
              <div className="mt-auto">
                <Button
                  onClick={() => handleRemove(item)}
                  variant="danger"
                  className="w-100"
                >
                  Remove from Favorites
                </Button>
                <Button
                  onClick={() =>
                    navigate(`/similar/${item.media_type}/${item.id}`)
                  }
                  variant="info"
                  className="w-100 mt-2"
                >
                  Similar Movies & Shows
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    });
  };

  const filteredItems = (items) => {
    return items.filter((item) =>
      (item.title || item.name).toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <Container>
      <h1>My Favorites</h1>
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-4">
        <Tab eventKey="movies" title="Movies">
          {favorites.movies.length === 0 ? (
            <p>Your favorites list is empty.</p>
          ) : (
            <>
              {renderGenresButtons('movie')}
              <div className="d-flex justify-content-start mb-3">
                {renderDecadeDropdown('movie')}
              </div>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search Favorites"
                  style={{ maxWidth: '300px' }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button
                  variant="secondary"
                  className="ms-2"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
              <Row>
                {renderItems(
                  filteredItems(
                    favorites.movies.filter((item) => {
                      const genresMatch =
                        selectedGenre.movie === 'All' ||
                        item.genre_ids.some(
                          (id) => genres.movie[id] === selectedGenre.movie
                        );
                      const decadeMatch =
                        selectedDecade.movie === 'All' ||
                        (item.release_date &&
                          parseInt(item.release_date.split('-')[0], 10) >= selectedDecade.movie &&
                          parseInt(item.release_date.split('-')[0], 10) < selectedDecade.movie + 10);
                      return genresMatch && decadeMatch;
                    })
                  ),
                  'movie'
                )}
              </Row>
            </>
          )}
        </Tab>
        <Tab eventKey="shows" title="Shows">
          {favorites.shows.length === 0 ? (
            <p>Your favorites list is empty.</p>
          ) : (
            <>
              {renderGenresButtons('tv')}
              <div className="d-flex justify-content-start mb-3">
                {renderDecadeDropdown('tv')}
              </div>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search Favorites"
                  style={{ maxWidth: '300px' }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button
                  variant="secondary"
                  className="ms-2"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
              <Row>
                {renderItems(
                  filteredItems(
                    favorites.shows.filter((item) => {
                      const genresMatch =
                        selectedGenre.tv === 'All' ||
                        item.genre_ids.some(
                          (id) => genres.tv[id] === selectedGenre.tv
                        );
                      const decadeMatch =
                        selectedDecade.tv === 'All' ||
                        (item.first_air_date &&
                          parseInt(item.first_air_date.split('-')[0], 10) >= selectedDecade.tv &&
                          parseInt(item.first_air_date.split('-')[0], 10) < selectedDecade.tv + 10);
                      return genresMatch && decadeMatch;
                    })
                  ),
                  'tv'
                )}
              </Row>
            </>
          )}
        </Tab>
      </Tabs>
      {showBackToTop && (
        <button
          onClick={handleBackToTop}
          className="btn btn-primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            borderRadius: '50%',
            fontSize: '20px',
            color: 'white',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
    </Container>
  );
};

export default FavoritesPage;
