import React from 'react';
import { auth, provider, signInWithPopup } from '../firebase'; // Adjust the import path as necessary

const GoogleSignInButton = () => {
  const handleSignIn = async () => {
    try {
      // Configure the provider to prompt for account selection
      provider.setCustomParameters({ prompt: 'select_account' });

      const result = await signInWithPopup(auth, provider);
      // The signed-in user info
      const user = result.user;
      console.log('User:', user);
      // Redirect or handle post-login logic here
    } catch (error) {
      console.error('Error during Google Sign-In:', error.message);
    }
  };

  return (
    <button onClick={handleSignIn} className="btn btn-primary">
      Sign in with Google
    </button>
  );
};

export default GoogleSignInButton;
