const StarRating = ({ rating, onRatingChange }) => {
    const stars = [1, 2, 3, 4, 5];
  
    return (
      <div style={{ margin: '15px 0' }}> {/* Adjust space above and below */}
        {stars.map((star) => (
          <span
            key={star}
            style={{
              cursor: 'pointer',
              color: star <= rating ? 'gold' : 'grey',
              fontSize: '20px', // Adjust star size to be smaller
              margin: '0 3px' // Adjust space between stars
            }}
            onClick={() => onRatingChange(star)}
          >
            ★
          </span>
        ))}
      </div>
    );
  };
  
  export default StarRating;
  