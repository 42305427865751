import React, { useState } from 'react';
import axios from 'axios';
import { Card, Button, Row, Col } from 'react-bootstrap';

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const AIMovieSuggestionsPage = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = async (retryCount = 0) => {
    try {
      // Call OpenAI API to generate keywords or specific search criteria using gpt-3.5-turbo
      const aiResponse = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Generate a keyword or phrase to search for movies based on: "${query}"` }
          ],
          max_tokens: 10,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
          },
        }
      );

      const keywords = aiResponse.data.choices[0].message.content.trim();

      console.log('Keywords generated by AI:', keywords); // Log the generated keywords

      // Use the keywords to search TMDB for matching movies or shows
      const response = await axios.get(
        `https://api.themoviedb.org/3/search/multi?api_key=${API_KEY}&query=${keywords}`
      );

      console.log('TMDB API Response:', response.data); // Log the API response
      setResults(response.data.results);

    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Rate limit exceeded. Retrying after delay...');
        if (retryCount < 3) { // Limit retries to prevent infinite loops
          setTimeout(() => handleSearch(retryCount + 1), 5000); // Retry after 5 seconds
        } else {
          alert('Rate limit exceeded. Please try again later.');
        }
      } else {
        console.error('Error fetching movie suggestions:', error.response?.data || error.message);
        alert('There was an issue fetching movie suggestions. Please try again later.');
      }
    }
  };

  return (
    <div className="container mt-4">
      <h1>AI Movie & Show Suggestions</h1>
      <div className="search-bar mt-2 p-3">
        <input
          type="text"
          placeholder="Describe the type of movie or show you want to watch..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="form-control"
        />
        <Button className="btn btn-primary mt-2" onClick={() => handleSearch()}>
          Search
        </Button>
      </div>
      <Row xs={1} md={2} lg={4} className="g-4 mt-4">
        {results.map((item) => (
          <Col key={item.id}>
            <Card className="h-100">
              <Card.Img
                variant="top"
                src={item.poster_path ? `https://image.tmdb.org/t/p/w500${item.poster_path}` : 'https://via.placeholder.com/150'}
                alt={`${item.title || item.name} Poster`}
              />
              <Card.Body>
                <Card.Title>{item.title || item.name}</Card.Title>
                <Card.Text>{item.overview ? `${item.overview.substring(0, 100)}...` : 'No description available'}</Card.Text>
                <Button variant="primary">More Info</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AIMovieSuggestionsPage;
