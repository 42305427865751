import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleSignInButton from '../components/GoogleSignInButton'; // Adjust the import path as necessary
import { useAuth } from '../hooks/useAuth';

const RegisterPage = () => {
  const { user } = useAuth(); // Get user from context
  const navigate = useNavigate(); // Get navigate function from react-router-dom

  // Redirect to home page if user is logged in
  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div className="container">
      <h1>Login</h1>
      <GoogleSignInButton />
      {/* Your other registration logic here */}
    </div>
  );
};

export default RegisterPage;
