import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider, useAuth } from './context/AuthContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';

function MainApp() {
  const { user } = useAuth();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js')
          .then(function(registration) {
            console.log('Service Worker registered with scope:', registration.scope);

            if ('PushManager' in window) {
              registration.pushManager.getSubscription()
                .then(async function(subscription) {
                  if (!subscription) {
                    // If no subscription, request permission and subscribe
                    const permission = await Notification.requestPermission();
                    if (permission === 'granted') {
                      console.log('Notification permission granted.');
                      subscribeUserToPush(registration, user);
                    } else {
                      console.error('Unable to get permission to notify.');
                    }
                  }
                })
                .catch(function(error) {
                  console.error('Push Manager Error:', error);
                });
            }
          })
          .catch(function(error) {
            console.error('Service Worker registration failed:', error);
          });
      });
    }
  }, [user]);

  return <App />;
}

async function subscribeUserToPush(swRegistration, user) {
  if (!user) {
    console.error('No user logged in.');
    return;
  }

  const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

  try {
    const subscription = await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
    });

    console.log('User is subscribed:', subscription);

    // Store subscription in Firestore
    const subscriptionRef = doc(db, 'subscriptions', user.uid);
    await setDoc(subscriptionRef, {
      subscription: subscription.toJSON(),
    });

    console.log('Subscription stored in Firestore.');
  } catch (error) {
    console.error('Failed to subscribe the user:', error);
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// Register the service worker
serviceWorkerRegistration.register();
