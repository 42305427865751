import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, Button, Modal } from 'react-bootstrap'; // Import Modal
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebase';
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import StarRating from '../components/StarRating'; // Import the StarRating component

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const PopularMoviesPage = () => {
  const [content, setContent] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [activeTab, setActiveTab] = useState('movie');
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(0);
  const [favorites, setFavorites] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const observer = useRef();
  const { user } = useAuth();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const endpoint =
          activeTab === 'movie'
            ? query
              ? `https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&query=${query}&page=${page}`
              : `https://api.themoviedb.org/3/movie/popular?api_key=${API_KEY}&page=${page}`
            : query
            ? `https://api.themoviedb.org/3/search/tv?api_key=${API_KEY}&query=${query}&page=${page}`
            : `https://api.themoviedb.org/3/tv/popular?api_key=${API_KEY}&page=${page}`;

        const response = await axios.get(endpoint);
        setContent((prevContent) => [...prevContent, ...response.data.results]);
        setHasMore(response.data.results.length > 0);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchContent();
  }, [query, page, activeTab]);

  useEffect(() => {
    const handleScroll = () => {
      setShowTopBtn(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userFavoritesRef = doc(db, 'favorites', user.uid);
          const userFavoritesDoc = await getDoc(userFavoritesRef);
          if (userFavoritesDoc.exists()) {
            setFavorites(userFavoritesDoc.data().items || []);
          }

          const userWatchlistRef = doc(db, 'watchlists', user.uid);
          const userWatchlistDoc = await getDoc(userWatchlistRef);
          if (userWatchlistDoc.exists()) {
            setWatchlist(userWatchlistDoc.data().movies || []);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleSearch = (event) => {
    setQuery(event.target.value);
    setContent([]); // Clear current content
    setPage(1); // Reset to first page
  };

  const handleClear = () => {
    setQuery('');
    setContent([]);
    setPage(1);
  };

  const lastContentElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const addToWatchlist = async (item) => {
    if (!user) {
      alert(`Please log in to add ${activeTab}s to your watchlist.`);
      return;
    }

    try {
      const itemWithMediaType = { ...item, media_type: activeTab };

      const userWatchlistRef = doc(db, 'watchlists', user.uid);
      const userWatchlistDoc = await getDoc(userWatchlistRef);

      if (userWatchlistDoc.exists()) {
        await updateDoc(userWatchlistRef, {
          movies: arrayUnion(itemWithMediaType),
        });
        setWatchlist((prevWatchlist) => [...prevWatchlist, itemWithMediaType]);
      } else {
        await setDoc(userWatchlistRef, { movies: [itemWithMediaType] });
        setWatchlist([itemWithMediaType]);
      }
      alert(`${item.title || item.name} has been added to your watchlist!`);
    } catch (error) {
      console.error('Error adding item to watchlist:', error);
      alert(
        `There was an issue adding the ${activeTab} to your watchlist. ${error.message}`
      );
    }
  };

  const addToFavorites = async (item) => {
    if (!user) {
      alert(`Please log in to add ${activeTab}s to your favorites.`);
      return;
    }

    // Show the modal for rating selection
    setSelectedItem({ ...item, media_type: activeTab });
    setShowModal(true);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const saveToFavorites = async () => {
    if (!user || !selectedItem) return;

    try {
      const itemWithRating = { ...selectedItem, rating };

      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      if (userFavoritesDoc.exists()) {
        await updateDoc(userFavoritesRef, {
          items: arrayUnion(itemWithRating),
        });
        setFavorites((prevFavorites) => [...prevFavorites, itemWithRating]);
      } else {
        await setDoc(userFavoritesRef, { items: [itemWithRating] });
        setFavorites([itemWithRating]);
      }

      alert(`${selectedItem.title || selectedItem.name} has been added to your favorites with a ${rating}-star rating!`);
      setShowModal(false); // Close the modal
      setRating(0); // Reset the rating
    } catch (error) {
      console.error('Error adding item to favorites:', error);
      alert(
        `There was an issue adding the ${activeTab} to your favorites. ${error.message}`
      );
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const isInFavorites = (item) => {
    return favorites.some(
      (favItem) => favItem.id === item.id && favItem.media_type === activeTab
    );
  };

  const isInWatchlist = (item) => {
    return watchlist.some(
      (wlItem) => wlItem.id === item.id && wlItem.media_type === activeTab
    );
  };

  const renderContentCard = (item, index) => {
    const imgSrc = item.poster_path
      ? `https://image.tmdb.org/t/p/w500${item.poster_path}`
      : 'https://via.placeholder.com/150';
    const isLastContent = content.length === index + 1;
    const releaseYear = item.release_date
      ? item.release_date.split('-')[0]
      : item.first_air_date
      ? item.first_air_date.split('-')[0]
      : 'Unknown';

    const briefDescription = item.overview
      ? item.overview.substring(0, 100) + '...'
      : 'No description available';
    const isExpanded = expandedDescriptions[item.id];

    return (
      <div
        ref={isLastContent ? lastContentElementRef : null}
        key={item.id}
        className="col-lg-3 col-md-4 col-sm-6 mb-4"
      >
        <div className="card h-100">
          <img
            src={imgSrc}
            alt={`${item.title || item.name} Poster`}
            className="card-img-top"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain', // Ensures the whole image is visible without cropping
              borderRadius: '0.25rem',
              position: 'relative',
            }}
          />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">{item.title || item.name}</h5>
            <p className="card-text">Rating: {item.vote_average}</p>
            <p className="card-text">Year: {releaseYear}</p>
            <div
              onClick={() => toggleDescription(item.id)}
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: '#f9f9f9',
                marginBottom: '20px',
                cursor: 'pointer',
              }}
            >
              <p className="card-text">
                {isExpanded ? item.overview : briefDescription}
              </p>
              <Button
                variant="link"
                style={{ padding: '0', textDecoration: 'none', color: '#007bff' }}
              >
                {isExpanded ? 'View Less' : 'View More'}
              </Button>
            </div>
            <div className="mt-auto d-flex flex-column align-items-center gap-2">
              <button
                onClick={() => addToFavorites(item)}
                className={`btn ${
                  isInFavorites(item) ? 'btn-secondary' : 'btn-success'
                } w-100`}
                disabled={isInFavorites(item)}
                title={
                  isInFavorites(item)
                    ? 'Already in Favorites'
                    : 'Add to Favorites'
                }
                style={{
                  cursor: isInFavorites(item) ? 'not-allowed' : 'pointer',
                }}
              >
                {isInFavorites(item) ? 'In Favorites' : 'Add to Favorites'}
              </button>
              <button
                onClick={() => addToWatchlist(item)}
                className={`btn ${
                  isInWatchlist(item) ? 'btn-secondary' : 'btn-primary'
                } w-100`}
                disabled={isInWatchlist(item)}
                title={
                  isInWatchlist(item)
                    ? 'Already in Watchlist'
                    : 'Add to Watchlist'
                }
                style={{
                  cursor: isInWatchlist(item) ? 'not-allowed' : 'pointer',
                }}
              >
                {isInWatchlist(item) ? 'In Watchlist' : 'Add to Watchlist'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="search-bar mt-2 p-3">
        <input
          type="text"
          placeholder={`Search for a ${activeTab === 'tv' ? 'show' : activeTab}...`}
          value={query}
          onChange={handleSearch}
          className="form-control"
        />
        <button className="btn btn-secondary mt-2" onClick={handleClear}>
          Clear
        </button>
      </div>
      <div className="container mt-4">
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => {
            setActiveTab(k);
            setContent([]); // Clear content when switching tabs
            setPage(1); // Reset to first page when switching tabs
          }}
          className="mb-4"
        >
          <Tab eventKey="movie" title="Movies">
            <div className="row">
              {content.map((item, index) => renderContentCard(item, index))}
            </div>
          </Tab>
          <Tab eventKey="tv" title="Shows">
            <div className="row">
              {content.map((item, index) => renderContentCard(item, index))}
            </div>
          </Tab>
        </Tabs>
      </div>
      {showTopBtn && (
        <button
          onClick={scrollToTop}
          className="btn btn-primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            borderRadius: '50%',
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      )}

      {/* Modal for rating selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rate {selectedItem?.title || selectedItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StarRating rating={rating} onRatingChange={handleRatingChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveToFavorites}>
            Save to Favorites
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopularMoviesPage;
