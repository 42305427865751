import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth'; // Adjust the import path as necessary
import { db } from '../firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

function ProfilePage() {
  const { user } = useAuth();
  const [followers, setFollowers] = useState([]);

  useEffect(() => {
    const fetchFollowers = async () => {
      if (!user) return;
    
      try {
        const followersQuery = query(
          collection(db, 'follows'),
          where('following', 'array-contains', user.uid)
        );
        const querySnapshot = await getDocs(followersQuery);
    
        const followerIds = querySnapshot.docs.map((doc) => doc.id);
    
        const followerDataPromises = followerIds.map(async (followerId) => {
          const favoritesDoc = await getDoc(doc(db, 'favorites', followerId));
          if (favoritesDoc.exists()) {
            const userData = favoritesDoc.data();
            const userName = userData.userName || 'Unknown User';
            return {
              id: followerId,
              username: userName,
            };
          } else {
            console.log(`No user data found for ID ${followerId}`);
            return { id: followerId, username: 'Unknown User' };
          }
        });
    
        const followerData = await Promise.all(followerDataPromises);
        setFollowers(followerData);
      } catch (error) {
        console.error('Error fetching followers:', error);
      }
    };      

    fetchFollowers();
  }, [user]);

  return (
    <div className="container mt-4">
      <h1>Profile Page</h1>
      {user ? (
        <div>
          <p>Username: {user.displayName || 'No display name available'}</p>
          <p>Email: {user.email || 'No email available'}</p>
          <p>Total Followers: {followers.length}</p>
          {followers.length > 0 ? (
            <ul>
              {followers.map((follower) => (
                <li key={follower.id}>
                  <Link to={`/user/${follower.id}/favorites`}>{follower.username}</Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>No followers yet.</p>
          )}
        </div>
      ) : (
        <p>No user information available.</p>
      )}
    </div>
  );
}

export default ProfilePage;
