import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import { doc, getDoc, updateDoc, setDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { Tab, Tabs, Button, Row, Col, Dropdown, DropdownButton, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import StarRating from '../components/StarRating';

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const WatchlistPage = () => {
  const [watchlist, setWatchlist] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeTab, setActiveTab] = useState('movie');
  const [genres, setGenres] = useState({ movie: {}, tv: {} });
  const [selectedGenre, setSelectedGenre] = useState({ movie: 'All', tv: 'All' });
  const [selectedYearRange, setSelectedYearRange] = useState({ movie: 'All', tv: 'All' });
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(0);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const movieGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/movie/list?api_key=${API_KEY}`
        );
        const tvGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/tv/list?api_key=${API_KEY}`
        );

        setGenres({
          movie: movieGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
          tv: tvGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
        });
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  useEffect(() => {
    const fetchWatchlistAndFavorites = async () => {
      if (user) {
        try {
          const userWatchlistRef = doc(db, 'watchlists', user.uid);
          const userWatchlistDoc = await getDoc(userWatchlistRef);

          if (userWatchlistDoc.exists()) {
            const uniqueMovies = removeDuplicates(userWatchlistDoc.data().movies);
            setWatchlist(uniqueMovies.reverse());
          }

          const userFavoritesRef = doc(db, 'favorites', user.uid);
          const userFavoritesDoc = await getDoc(userFavoritesRef);

          if (userFavoritesDoc.exists()) {
            setFavorites(removeDuplicates(userFavoritesDoc.data().items || []));
          }
        } catch (error) {
          console.error('Error fetching watchlist or favorites:', error);
        }
      }
    };

    fetchWatchlistAndFavorites();
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const removeDuplicates = (items) => {
    const itemMap = new Map();
    items.forEach((item) => {
      if (!itemMap.has(item.id)) {
        itemMap.set(item.id, item);
      }
    });
    return Array.from(itemMap.values());
  };

  const handleRemove = async (itemId) => {
    try {
      const itemToRemove = watchlist.find((item) => item.id === itemId);
      const updatedWatchlist = watchlist.filter((item) => item.id !== itemId);
      setWatchlist(updatedWatchlist);

      // Update watchlist in Firestore
      const userWatchlistRef = doc(db, 'watchlists', user.uid);
      await updateDoc(userWatchlistRef, {
        movies: arrayRemove(itemToRemove),
      });

      // Also remove the item from favorites if it exists there
      if (isInFavorites(itemToRemove)) {
        const updatedFavorites = favorites.filter((fav) => fav.id !== itemId);
        setFavorites(updatedFavorites);

        // Update favorites in Firestore
        const userFavoritesRef = doc(db, 'favorites', user.uid);
        await updateDoc(userFavoritesRef, {
          items: arrayRemove(itemToRemove),
        });
      }
    } catch (error) {
      console.error('Error removing item from watchlist or favorites:', error);
    }
  };

  const handleGenreSelect = (genre, type) => {
    setSelectedGenre((prev) => ({
      ...prev,
      [type]: prev[type] === genre ? 'All' : genre,
    }));
  };

  const handleYearSelect = (yearRange, type) => {
    setSelectedYearRange((prev) => ({
      ...prev,
      [type]: prev[type] === yearRange ? 'All' : yearRange,
    }));
  };

  const filterItemsBySelectedGenre = (items, type) => {
    if (selectedGenre[type] === 'All') return items;

    return items.filter((item) => {
      const itemGenres = item.genre_ids.map((id) => genres[type][id]);
      return itemGenres.includes(selectedGenre[type]);
    });
  };

  const filterItemsBySelectedYear = (items, type) => {
    if (selectedYearRange[type] === 'All') return items;

    const [startYear, endYear] = selectedYearRange[type].split('-').map(Number);

    return items.filter((item) => {
      const year = item.release_date
        ? parseInt(item.release_date.split('-')[0], 10)
        : item.first_air_date
        ? parseInt(item.first_air_date.split('-')[0], 10)
        : null;

      return year && year >= startYear && year <= endYear;
    });
  };

  const filteredItems = (items) => {
    return items.filter((item) =>
      (item.title || item.name).toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleNavigateToSimilar = (mediaType, itemId) => {
    navigate(`/similar/${mediaType}/${itemId}`);
  };

  const addToFavorites = async (item) => {
    if (!user) {
      alert('Please log in to add items to your favorites.');
      return;
    }

    setSelectedItem(item);
    setShowModal(true);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const saveToFavorites = async () => {
    if (!user || !selectedItem) return;

    try {
      const itemWithRating = { ...selectedItem, rating };

      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      if (userFavoritesDoc.exists()) {
        await updateDoc(userFavoritesRef, {
          items: arrayUnion(itemWithRating),
        });
        setFavorites((prevFavorites) => removeDuplicates([...prevFavorites, itemWithRating]));
      } else {
        await setDoc(userFavoritesRef, { items: [itemWithRating] });
        setFavorites([itemWithRating]);
      }

      alert(`${selectedItem.title || selectedItem.name} has been added to your favorites with a ${rating}-star rating!`);
      setShowModal(false); // Close the modal
      setRating(0); // Reset the rating
    } catch (error) {
      console.error('Error adding item to favorites:', error);
      alert(`There was an issue adding the item to your favorites. ${error.message}`);
    }
  };

  const isInFavorites = (item) => {
    return favorites.some((favoriteItem) => favoriteItem.id === item.id);
  };

  const renderGenresButtons = (type) => {
    const uniqueGenres = Array.from(
      new Set(
        watchlist
          .filter((item) => item.media_type === type)
          .flatMap((item) => item.genre_ids.map((id) => genres[type][id]))
          .filter((genre) => genre !== undefined)
      )
    );

    return (
      <div className="d-flex flex-nowrap overflow-auto mb-3">
        <Button
          variant={selectedGenre[type] === 'All' ? 'primary' : 'light'}
          className="me-2"
          onClick={() => handleGenreSelect('All', type)}
        >
          All
        </Button>
        {uniqueGenres.map((genre, index) => (
          <Button
            key={index}
            variant={selectedGenre[type] === genre ? 'primary' : 'light'}
            className="me-2"
            onClick={() => handleGenreSelect(genre, type)}
          >
            {genre}
          </Button>
        ))}
      </div>
    );
  };

  const renderYearsDropdown = (type) => {
    const items = watchlist.filter((item) => item.media_type === type);
    const years = items
      .map((item) => {
        const year = item.release_date
          ? item.release_date.split('-')[0]
          : item.first_air_date
          ? item.first_air_date.split('-')[0]
          : null;
        return parseInt(year, 10);
      })
      .filter((year) => !isNaN(year));

    const uniqueYears = Array.from(new Set(years.map((year) => Math.floor(year / 10) * 10)));

    const currentYearRange = selectedYearRange[type];
    const title = currentYearRange === 'All' ? 'Years: All' : `Years: ${currentYearRange}`;

    return (
      <DropdownButton
        id={`dropdown-year-${type}`}
        title={title}
        variant="secondary"
        className="mb-3"
        onSelect={(e) => handleYearSelect(e === 'All' ? 'All' : e, type)}
      >
        <Dropdown.Item eventKey="All" active={selectedYearRange[type] === 'All'}>
          All
        </Dropdown.Item>
        {uniqueYears.sort((a, b) => b - a).map((year, index) => (
          <Dropdown.Item
            key={index}
            eventKey={`${year}-${year + 9}`}
            active={selectedYearRange[type] === `${year}-${year + 9}`}
          >
            {`${year}-${year + 9}`}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };

  const renderMovieCard = (movie) => {
    const releaseYear = movie.release_date
      ? movie.release_date.split('-')[0]
      : movie.first_air_date
      ? movie.first_air_date.split('-')[0]
      : 'Unknown';

    const briefDescription = movie.overview ? movie.overview.substring(0, 100) + '...' : 'No description available';
    const isExpanded = expandedDescriptions[movie.id];

    return (
      <Col key={movie.id} lg={3} md={6} sm={12} className="mb-4">
        <div className="card h-100">
          <img
            src={
              movie.poster_path
                ? `https://image.tmdb.org/t/p/w500${movie.poster_path}`
                : 'https://via.placeholder.com/150'
            }
            alt={`${movie.title} Poster`}
            className="card-img-top"
            style={{ 
              width: '100%',
              height: 'auto',
              objectFit: 'contain', // Ensures the whole image is visible without cropping
              borderRadius: '0.25rem',
              position: 'relative',
            }}
          />
          <div className="card-body d-flex flex-column justify-content-between">
            <div>
              <h5 className="card-title">{movie.title || movie.name}</h5>
              <p className="card-text">Year: {releaseYear}</p>
              <p className="card-text mb-4">Rating: {movie.vote_average}</p>
              <div
                onClick={() => toggleDescription(movie.id)}
                style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#f9f9f9',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
              >
                <p className="card-text">{isExpanded ? movie.overview : briefDescription}</p>
                <Button
                  variant="link"
                  style={{ padding: '0', textDecoration: 'none', color: '#007bff' }}
                >
                  {isExpanded ? 'View Less' : 'View More'}
                </Button>
              </div>
            </div>
            <Button
              onClick={() => handleRemove(movie.id)}
              variant="danger"
              className="w-100 mt-auto"
            >
              Remove from Watchlist
            </Button>
            <Button
              onClick={() => addToFavorites(movie)}
              variant="success"
              disabled={isInFavorites(movie)}
              className="w-100 mt-2"
            >
              {isInFavorites(movie) ? 'In Favorites' : 'Add to Favorites'}
            </Button>
            <Button
              onClick={() => handleNavigateToSimilar(movie.media_type, movie.id)}
              variant="info"
              className="w-100 mt-2"
            >
              Similar Movies & Shows
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="container">
      <h1>My Watchlist</h1>
      {watchlist.length === 0 ? (
        <p>Your watchlist is empty.</p>
      ) : (
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="movie" title="Movies">
            {renderGenresButtons('movie')}
            {renderYearsDropdown('movie')}
            <div className="d-flex justify-content-center align-items-center mb-3">
              <Form.Control
                type="text"
                placeholder="Search Watchlist"
                style={{ maxWidth: '300px' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                variant="secondary"
                className="ms-2"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
            <Row>
              {filterItemsBySelectedYear(
                filterItemsBySelectedGenre(
                  filteredItems(
                    watchlist.filter((item) => item.media_type === 'movie')
                  ),
                  'movie'
                ),
                'movie'
              ).map(renderMovieCard)}
            </Row>
          </Tab>
          <Tab eventKey="tv" title="Shows">
            {renderGenresButtons('tv')}
            {renderYearsDropdown('tv')}
            <div className="d-flex justify-content-center align-items-center mb-3">
              <Form.Control
                type="text"
                placeholder="Search Watchlist"
                style={{ maxWidth: '300px' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                variant="secondary"
                className="ms-2"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
            <Row>
              {filterItemsBySelectedYear(
                filterItemsBySelectedGenre(
                  filteredItems(
                    watchlist.filter((item) => item.media_type === 'tv')
                  ),
                  'tv'
                ),
                'tv'
              ).map(renderMovieCard)}
            </Row>
          </Tab>
        </Tabs>
      )}

      {/* Back to Top Button */}
      {showBackToTop && (
        <button
          onClick={handleBackToTop}
          className="btn btn-primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            borderRadius: '50%',
            fontSize: '20px',
            color: 'white',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      )}

      {/* Modal for rating selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rate {selectedItem?.title || selectedItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StarRating rating={rating} onRatingChange={handleRatingChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveToFavorites}>
            Save to Favorites
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WatchlistPage;
