import React, { useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useAuth } from '../hooks/useAuth';

const Layout = ({ children }) => {
  const { user } = useAuth(); // Fetch user from AuthContext
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false); // State to control the navbar collapse

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error.message);
    }
  };

  const handleSelect = () => {
    setExpanded(false); // Collapse the navbar when a link is clicked
  };

  return (
    <>
      <div className="sticky-top bg-white shadow-sm" style={{ zIndex: 1000 }}>
        <Navbar bg="light" expand="lg" expanded={expanded} className="mx-2 mx-sm-4 mx-md-5">
          <Container>
            <Navbar.Brand as={Link} to="/">Flick Bff</Navbar.Brand>
            <Navbar.Toggle 
              aria-controls="basic-navbar-nav" 
              onClick={() => setExpanded(expanded ? false : true)} // Toggle the collapse state
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/" onClick={handleSelect}>Home</Nav.Link>
                <Nav.Link as={Link} to="/add-movies-shows" onClick={handleSelect}>Add Movies/Shows</Nav.Link>
                <Nav.Link as={Link} to="/favorites" onClick={handleSelect}>Favorites</Nav.Link>
                <Nav.Link as={Link} to="/watchlist" onClick={handleSelect}>Watchlist</Nav.Link>
                <Nav.Link as={Link} to="/popular-movies" onClick={handleSelect}>Popular Movies/Shows</Nav.Link>
                {/* <Nav.Link as={Link} to="/ai-suggestions">AI Suggestions</Nav.Link> */}
                <Nav.Link as={Link} to="/profile" onClick={handleSelect}>Profile</Nav.Link>
                {!user ? (
                  <Nav.Link as={Link} to="/login" onClick={handleSelect}>Login</Nav.Link>
                ) : (
                  <Button onClick={() => { handleLogout(); setExpanded(false); }} className="ml-2" variant="outline-danger">
                    Logout
                  </Button>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="container mt-4">
        {children}
      </div>
    </>
  );
};

export default Layout;
