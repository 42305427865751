import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
} from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {
  const { user } = useAuth(); // Fetch current user
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [usersWithFavorites, setUsersWithFavorites] = useState([]);
  const [search, setSearch] = useState('');
  const [matchesVisible, setMatchesVisible] = useState(false);
  const [currentUserMatches, setCurrentUserMatches] = useState([]);
  const [following, setFollowing] = useState([]); // State to store following list
  const [followingVisible, setFollowingVisible] = useState(false); // State to control following view visibility

  // Fetch following list and update it
  const fetchFollowing = useCallback(async () => {
    if (!user) return;

    try {
      const followsRef = doc(db, 'follows', user.uid);
      const followsDoc = await getDoc(followsRef);

      if (followsDoc.exists()) {
        setFollowing(followsDoc.data().following || []);
      } else {
        setFollowing([]);
      }
    } catch (error) {
      console.error('Error fetching following list:', error);
    }
  }, [user]);

  const fetchUsersWithFavorites = useCallback(async () => {
    try {
      const favoritesRef = collection(db, 'favorites');
      const q = query(favoritesRef, where('items', '!=', []));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const usersData = querySnapshot.docs
          .map((docSnapshot) => {
            const userId = docSnapshot.id;
            const userData = docSnapshot.data();
            const items = userData.items || [];
  
            if (items.length > 0) {
              const mostRecentItem = items[items.length - 1];
              const totalItemsCount = items.length;
              const displayCount =
                totalItemsCount > 100 ? '100+' : totalItemsCount - 1;
              const userName = userData.userName || 'Anonymous User';
  
              return {
                userId,
                userName,
                items,
                mostRecentItem,
                moreItemsCount: displayCount,
              };
            }
            return null;
          })
          .filter((user) => user !== null);
  
        // Ensure the current user's card is first
        const sortedUsersData = user
          ? usersData.sort((a, b) =>
              a.userId === user.uid ? -1 : b.userId === user.uid ? 1 : 0
            )
          : usersData;
  
        setUsersWithFavorites(sortedUsersData);
  
        if (user) {
          const currentUserData = await getDoc(doc(db, 'favorites', user.uid));
          if (currentUserData.exists()) {
            const currentUserItems = new Set(
              currentUserData.data().items.map((item) => item.id)
            );
  
            const matches = sortedUsersData
              .map((otherUser) => {
                if (otherUser.userId !== user.uid) {
                  const commonItems = otherUser.items.filter((item) =>
                    currentUserItems.has(item.id)
                  );
                  const matchCount = commonItems.length;
                  return {
                    ...otherUser,
                    matchCount: matchCount > 100 ? '100+' : matchCount,
                  };
                }
                return null;
              })
              .filter((match) => match !== null && match.matchCount > 0);
  
            setCurrentUserMatches(matches);
          }
        }
      } else {
        setUsersWithFavorites([]);
      }
    } catch (error) {
      console.error(
        'Error fetching users with favorited movies/shows:',
        error
      );
    }
  }, [user]);  

  useEffect(() => {
    fetchUsersWithFavorites();
    fetchFollowing(); // Fetch following list
  }, [user, fetchFollowing, fetchUsersWithFavorites]); // Add fetchFollowing to the dependency array

  // Reset matches and visibility when user logs out
  useEffect(() => {
    if (!user) {
      setCurrentUserMatches([]); // Reset matches
      setMatchesVisible(false); // Ensure matches view is not visible
      setFollowing([]); // Reset following list
    }
  }, [user]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  const toggleMatchesView = () => {
    if (user) {
      setMatchesVisible(!matchesVisible);
      setFollowingVisible(false); // Hide following view when matches view is shown
    }
  };

  const toggleFollowingView = () => {
    if (user) {
      setFollowingVisible(!followingVisible);
      setMatchesVisible(false); // Hide matches view when following view is shown
    }
  };

  const followUser = async (userIdToFollow) => {
    if (!user) {
      alert('Please log in to follow users.');
      navigate('/login'); // Redirect to login page
      return;
    }

    try {
      const followsRef = doc(db, 'follows', user.uid);
      const followsDoc = await getDoc(followsRef);

      if (followsDoc.exists()) {
        const followsData = followsDoc.data();
        if (followsData.following.includes(userIdToFollow)) {
          // Unfollow user
          await updateDoc(followsRef, {
            following: followsData.following.filter(
              (id) => id !== userIdToFollow
            ),
          });

          // If unfollowing in following view, redirect to all profiles
          if (followingVisible) {
            setFollowingVisible(false);
            setMatchesVisible(false);
          }
        } else {
          // Follow user
          await updateDoc(followsRef, {
            following: arrayUnion(userIdToFollow),
          });
        }
      } else {
        // Create new follows document if it doesn't exist
        await setDoc(followsRef, { following: [userIdToFollow] });
      }

      fetchFollowing(); // Refresh following list
    } catch (error) {
      console.error('Error following/unfollowing user:', error);
    }
  };

  const calculateMatchCount = (userData) => {
    if (!user) return 0;
    const currentUserData = usersWithFavorites.find(
      (u) => u.userId === user.uid
    );
    if (!currentUserData) return 0;
    const currentUserItems = new Set(
      currentUserData.items.map((item) => item.id)
    );
    const commonItems = userData.items.filter((item) =>
      currentUserItems.has(item.id)
    );
    return commonItems.length;
  };

  return (
    <div className="container mt-4">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3 gap-2">
        <h1>Profiles</h1>
        <input
          type="text"
          placeholder="Search by username..."
          value={search}
          onChange={handleSearchChange}
          className="form-control mb-3 mb-md-0"
          style={{ maxWidth: '300px' }}
        />
        <div className="d-flex gap-4">
          <button
            className="btn btn-light border" // Different style for refresh button
            onClick={fetchUsersWithFavorites}
          >
            <FontAwesomeIcon icon={faSync} size="lg" />
          </button>
          <button
            className={`btn ${
              matchesVisible ? 'btn-primary' : 'btn-outline-secondary'
            } position-relative`}
            onClick={toggleMatchesView}
            disabled={!user} // Disable the button if not logged in
          >
            Matches
            <span
              className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              style={{ transform: 'translate(-50%, -50%)' }}
            >
              {user ? currentUserMatches.length : 0}
            </span>
          </button>
          <button
            className={`btn ${
              followingVisible ? 'btn-primary' : 'btn-outline-secondary'
            } position-relative`}
            onClick={toggleFollowingView}
            disabled={!user} // Disable the button if not logged in
          >
            Following
            <span
              className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              style={{ transform: 'translate(-50%, -50%)' }}
            >
              {user ? following.length : 0}
            </span>
          </button>
        </div>
      </div>
      {usersWithFavorites.length > 0 ? (
        <div className="row">
          {(
            matchesVisible
              ? currentUserMatches
              : followingVisible
              ? usersWithFavorites.filter((u) => following.includes(u.userId))
              : usersWithFavorites
          )
            .filter((user) => user.userName.toLowerCase().includes(search))
            .map((userData, index) => {
              const isCurrentUser = user && userData.userId === user.uid;
              const isFollowing = following.includes(userData.userId);
              const matchCount = calculateMatchCount(userData);

              console.log(
                `Card User ID: ${userData.userId}, Logged-in User ID: ${
                  user ? user.uid : 'No User'
                }, isCurrentUser: ${isCurrentUser}`
              );

              return (
                <div key={index} className="col-lg-3 col-md-6 col-12 mb-4">
                  <Link
                    to={`/user/${userData.userId}/favorites`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div
                      className="card h-100 shadow-sm"
                      style={{
                        cursor: 'pointer',
                        border: isCurrentUser
                          ? '4px solid black'
                          : '1px solid #ddd',
                        backgroundColor: isCurrentUser ? '#f8f9fa' : '#fff',
                      }}
                    >
                      <img
                        src={`https://image.tmdb.org/t/p/w500${userData.mostRecentItem.poster_path}`}
                        alt={`${userData.mostRecentItem.title} Poster`}
                        className="card-img-top"
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain', // Ensures the whole image is visible without cropping
                          borderRadius: '0.25rem',
                          position: 'relative',
                        }}
                      />

                      {isCurrentUser && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            zIndex: 1,
                          }}
                        >
                          Your Profile
                        </div>
                      )}
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title">
                          {userData.mostRecentItem.title}
                        </h5>
                        <p className="card-text">Added by: {userData.userName}</p>
                        {userData.moreItemsCount > 0 && (
                          <p className="card-text">
                            {userData.moreItemsCount} more{' '}
                            {userData.moreItemsCount === '100+'
                              ? 'items'
                              : userData.moreItemsCount === 1
                              ? 'item'
                              : 'items'}{' '}
                            in Favorites
                          </p>
                        )}
                        {!isCurrentUser && matchCount > 0 && (
                          <p className="card-text text-success">
                            Matches: {matchCount}{' '}
                            {matchCount === 1 ? 'match' : 'matches'}
                          </p>
                        )}
                        {!isCurrentUser && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              followUser(userData.userId);
                            }}
                            className={`btn mt-auto ${
                              isFollowing
                                ? 'btn-primary text-white'
                                : 'btn-light text-primary'
                            }`}
                            style={{ width: '100%' }}
                          >
                            {isFollowing ? 'Unfollow' : 'Follow'}
                          </button>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      ) : (
        <p>No users found with favorited movies/shows.</p>
      )}
    </div>
  );
};

export default HomePage;
