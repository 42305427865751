import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCF93MrPnW1h2Bgy5Ow3rDOPkqQudA-WvI',
  authDomain: 'movie-db-social-network.firebaseapp.com',
  projectId: 'movie-db-social-network',
  storageBucket: 'movie-db-social-network.appspot.com',
  messagingSenderId: '867352424347',
  appId: '1:867352424347:web:17f65e87829a664a30fb3e',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app); // Initialize Firestore

export { auth, provider, signInWithPopup, db };
