import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import WatchlistPage from './pages/WatchlistPage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './authentication/Register';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import AddMoviesShowsPage from './pages/AddMoviesShowsPage';
import FavoritesPage from './pages/FavoritesPage';
import PopularMoviesPage from './pages/PopularMoviesPage';
import UserProfileFavorites from './components/UserProfileFavorites';
import SimilarItemsPage from './pages/SimilarItemsPage';
import AIMovieSuggestionsPage from './pages/AIMovieSuggestionsPage';
import { useAuth } from './context/AuthContext';

const App = () => {
  const { user } = useAuth();

  useEffect(() => {
    // Detect Instagram, Facebook, or TikTok in-app browser
    if (navigator.userAgent.match(/Instagram|FBAN|FBAV|TikTok/i)) {
      document.body.innerHTML = `
        <div style="text-align:center; padding:20px;">
          <h2>Open in Browser</h2>
          <p>To continue, please copy the link below and paste it into your browser:</p>
          <p>
            <input type="text" value="${window.location.href}" id="browserLink" style="width: 80%; padding: 10px;" readonly />
            <button id="copyLinkButton" style="padding: 10px 20px; font-size: 16px;">Copy Link</button>
          </p>
          <p>After copying, open your browser and paste the link to continue.</p>
        </div>
      `;

      document.getElementById('copyLinkButton').addEventListener('click', () => {
        const copyText = document.getElementById("browserLink");
        copyText.select();
        document.execCommand("copy");
        alert("Link copied! Now paste it in your browser.");
      });

      return; // Exit the useEffect early
    }

    const urlBase64ToUint8Array = (base64String) => {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
    };

    const subscribeUserToPush = async () => {
      if (!user) return;

      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        });

        // Send subscription to your server
        await fetch('/api/subscribe', {
          method: 'POST',
          body: JSON.stringify({
            userId: user.uid,
            subscription: subscription,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log('User is subscribed:', subscription);
      } catch (error) {
        console.error('Failed to subscribe the user:', error);
      }
    };

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then(() => {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            subscribeUserToPush();
          } else {
            console.error('Notification permission denied.');
          }
        });
      }).catch(error => console.error('Service Worker Error', error));
    }
  }, [user]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/watchlist" element={<ProtectedRoute element={WatchlistPage} />} />
        <Route path="/profile" element={<ProtectedRoute element={ProfilePage} />} />
        <Route path="/add-movies-shows" element={<ProtectedRoute element={AddMoviesShowsPage} />} />
        <Route path="/favorites" element={<ProtectedRoute element={FavoritesPage} />} />
        <Route path="/popular-movies" element={<PopularMoviesPage />} />
        <Route path="/user/:userId/favorites" element={<UserProfileFavorites />} />
        <Route path="/similar/:mediaType/:itemId" element={<SimilarItemsPage />} />
        <Route path="/ai-suggestions" element={<AIMovieSuggestionsPage />} />
      </Routes>
    </Layout>
  );
};

export default App;
