import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { doc, getDoc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import { Card, Button, Row, Col, Tabs, Tab, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa';
import StarRating from '../components/StarRating';

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const SimilarItemsPage = () => {
  const { mediaType, itemId } = useParams();
  const [similarMovies, setSimilarMovies] = useState([]);
  const [similarShows, setSimilarShows] = useState([]);
  const [additionalMovies, setAdditionalMovies] = useState([]);
  const [additionalShows, setAdditionalShows] = useState([]);
  const [activeTab, setActiveTab] = useState(mediaType || 'movie');
  const [currentTitle, setCurrentTitle] = useState('');
  const { user } = useAuth();
  const [watchlist, setWatchlist] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [genres, setGenres] = useState({ movie: {}, tv: {} });
  const [selectedGenre, setSelectedGenre] = useState('All');
  const [selectedYearRange, setSelectedYearRange] = useState('All');
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(0);
  const observer = useRef();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  useEffect(() => {
    const fetchSelectedItemTitle = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/${mediaType}/${itemId}?api_key=${API_KEY}`
        );
        const title = response.data.title || response.data.name || 'Title Not Found';
        setCurrentTitle(title);
      } catch (error) {
        console.error('Error fetching the selected item title:', error);
      }
    };

    const fetchSimilarItems = async () => {
      try {
        if (activeTab === 'movie') {
          const response = await axios.get(
            `https://api.themoviedb.org/3/movie/${itemId}/similar?api_key=${API_KEY}`
          );
          setSimilarMovies(response.data.results);
          setAdditionalMovies([]); // Reset additional items when tab changes
        } else if (activeTab === 'tv') {
          const response = await axios.get(
            `https://api.themoviedb.org/3/tv/${itemId}/similar?api_key=${API_KEY}`
          );
          setSimilarShows(response.data.results);
          setAdditionalShows([]); // Reset additional items when tab changes
        }
      } catch (error) {
        console.error('Error fetching similar items:', error);
      }
    };

    const fetchUserLists = async () => {
      if (user) {
        try {
          const userWatchlistRef = doc(db, 'watchlists', user.uid);
          const userWatchlistDoc = await getDoc(userWatchlistRef);
          if (userWatchlistDoc.exists()) {
            setWatchlist(userWatchlistDoc.data().movies || []);
          }

          const userFavoritesRef = doc(db, 'favorites', user.uid);
          const userFavoritesDoc = await getDoc(userFavoritesRef);
          if (userFavoritesDoc.exists()) {
            setFavorites(userFavoritesDoc.data().items || []);
          }
        } catch (error) {
          console.error('Error fetching user lists:', error);
        }
      }
    };

    const fetchGenres = async () => {
      try {
        const movieGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/movie/list?api_key=${API_KEY}`
        );
        const tvGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/tv/list?api_key=${API_KEY}`
        );

        setGenres({
          movie: movieGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
          tv: tvGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
        });
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchSelectedItemTitle();
    fetchSimilarItems();
    fetchUserLists();
    fetchGenres();
  }, [itemId, mediaType, activeTab, user]);

  const loadMoreItems = useCallback(async () => {
    if (isLoadingMore) return;

    setIsLoadingMore(true);

    try {
      if (activeTab === 'movie') {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/${itemId}/similar?api_key=${API_KEY}&page=${Math.floor(additionalMovies.length / 20) + 2}`
        );
        setAdditionalMovies((prevItems) => [...prevItems, ...response.data.results]);
      } else if (activeTab === 'tv') {
        const response = await axios.get(
          `https://api.themoviedb.org/3/tv/${itemId}/similar?api_key=${API_KEY}&page=${Math.floor(additionalShows.length / 20) + 2}`
        );
        setAdditionalShows((prevItems) => [...prevItems, ...response.data.results]);
      }
    } catch (error) {
      console.error('Error loading more items:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, additionalMovies.length, additionalShows.length, activeTab, itemId]);

  const lastItemRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    });
    if (node) observer.current.observe(node);
  }, [loadMoreItems]);

  const addToWatchlist = async (item) => {
    if (!user) {
      alert('Please log in to add items to your watchlist.');
      return;
    }

    try {
      const itemWithMediaType = { ...item, media_type: activeTab };

      const userWatchlistRef = doc(db, 'watchlists', user.uid);
      const userWatchlistDoc = await getDoc(userWatchlistRef);

      if (userWatchlistDoc.exists()) {
        await updateDoc(userWatchlistRef, {
          movies: arrayUnion(itemWithMediaType),
        });
      } else {
        await setDoc(userWatchlistRef, { movies: [itemWithMediaType] });
      }

      setWatchlist((prevList) => [...prevList, itemWithMediaType]);
      alert(`${item.title || item.name} has been added to your watchlist!`);
    } catch (error) {
      console.error('Error adding item to watchlist:', error);
      alert(`There was an issue adding the item to your watchlist. ${error.message}`);
    }
  };

  const addToFavorites = async (item) => {
    if (!user) {
      alert('Please log in to add items to your favorites.');
      return;
    }

    setSelectedItem(item);
    setShowModal(true);
  };

  const saveToFavorites = async () => {
    if (!user || !selectedItem) return;

    try {
      const itemWithRating = { ...selectedItem, rating, media_type: activeTab };

      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      if (userFavoritesDoc.exists()) {
        await updateDoc(userFavoritesRef, {
          items: arrayUnion(itemWithRating),
        });
        setFavorites((prevList) => [...prevList, itemWithRating]);
      } else {
        await setDoc(userFavoritesRef, { items: [itemWithRating] });
        setFavorites([itemWithRating]);
      }

      alert(`${selectedItem.title || selectedItem.name} has been added to your favorites with a ${rating}-star rating!`);
      setShowModal(false);
      setRating(0);
    } catch (error) {
      console.error('Error adding item to favorites:', error);
      alert(`There was an issue adding the item to your favorites. ${error.message}`);
    }
  };

  const isItemInList = (list, itemId) => list.some((item) => item.id === itemId);

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const filterItemsBySelectedGenre = (items) => {
    if (selectedGenre === 'All') return items;

    return items.filter((item) => {
      const itemGenres = item.genre_ids.map((id) => genres[activeTab][id]);
      return itemGenres.includes(selectedGenre);
    });
  };

  const filterItemsBySelectedYear = (items) => {
    if (selectedYearRange === 'All') return items;

    const [startYear, endYear] = selectedYearRange.split('-').map(Number);

    return items.filter((item) => {
      const year = item.release_date
        ? parseInt(item.release_date.split('-')[0], 10)
        : item.first_air_date
        ? parseInt(item.first_air_date.split('-')[0], 10)
        : null;

      return year && year >= startYear && year <= endYear;
    });
  };

  const renderGenresButtons = () => {
    const items = activeTab === 'movie' ? similarMovies : similarShows;
    const uniqueGenres = Array.from(
      new Set(
        items
          .flatMap((item) => item.genre_ids.map((id) => genres[activeTab][id]))
          .filter((genre) => genre !== undefined)
      )
    );
  
    return (
      <div className="d-flex flex-nowrap overflow-auto mb-3">
        <Button
          variant={selectedGenre === 'All' ? 'primary' : 'light'}
          className="me-2"
          onClick={() => setSelectedGenre('All')}
        >
          All
        </Button>
        {uniqueGenres.map((genre, index) => (
          <Button
            key={index}
            variant={selectedGenre === genre ? 'primary' : 'light'}
            className="me-2"
            onClick={() =>
              setSelectedGenre((prevGenre) => (prevGenre === genre ? 'All' : genre))
            }
          >
            {genre}
          </Button>
        ))}
      </div>
    );
  };  

  const renderYearsDropdown = () => {
    const items = activeTab === 'movie' ? similarMovies : similarShows;
    const years = items
      .map((item) => {
        const year = item.release_date
          ? item.release_date.split('-')[0]
          : item.first_air_date
          ? item.first_air_date.split('-')[0]
          : null;
        return parseInt(year, 10);
      })
      .filter((year) => !isNaN(year));

    const uniqueYears = Array.from(new Set(years.map((year) => Math.floor(year / 10) * 10)));

    return (
      <DropdownButton
        id="dropdown-years"
        title={`Years: ${selectedYearRange === 'All' ? 'All' : selectedYearRange}`}
        variant="secondary"
        onSelect={(e) => setSelectedYearRange(e === 'All' ? 'All' : e)}
        className="mb-3"
      >
        <Dropdown.Item eventKey="All" active={selectedYearRange === 'All'}>
          All
        </Dropdown.Item>
        {uniqueYears.sort((a, b) => b - a).map((year, index) => (
          <Dropdown.Item
            key={index}
            eventKey={`${year}-${year + 9}`}
            active={selectedYearRange === `${year}-${year + 9}`}
          >
            {`${year}-${year + 9}`}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };

  const renderItems = (items) => {
    return items.map((item, index) => {
      const imgSrc = item.poster_path
        ? `https://image.tmdb.org/t/p/w500${item.poster_path}`
        : 'https://via.placeholder.com/150';
      const releaseYear = item.release_date
        ? item.release_date.split('-')[0]
        : item.first_air_date
        ? item.first_air_date.split('-')[0]
        : 'Unknown';

      const isInWatchlist = isItemInList(watchlist, item.id);
      const isInFavorites = isItemInList(favorites, item.id);
      const isExpanded = expandedDescriptions[item.id];

      return (
        <Col key={item.id} lg={3} md={4} sm={6} className="mb-4">
          <Card className="h-100">
            <Card.Img
              variant="top"
              src={imgSrc}
              alt={`${item.title || item.name} Poster`}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain', // Ensures the whole image is visible without cropping
                borderRadius: '0.25rem',
                position: 'relative',
              }}
            />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{item.title || item.name}</Card.Title>
              <Card.Text>Rating: {item.vote_average}</Card.Text>
              <Card.Text>Year: {releaseYear}</Card.Text>
              <div
                onClick={() => toggleDescription(item.id)}
                style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#f9f9f9',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
              >
                <p className="card-text">
                  {isExpanded ? item.overview : `${item.overview.substring(0, 100)}...`}
                </p>
                <Button
                  variant="link"
                  style={{ padding: '0', textDecoration: 'none', color: '#007bff' }}
                >
                  {isExpanded ? 'View Less' : 'View More'}
                </Button>
              </div>
              <div className="mt-auto d-flex flex-column align-items-center gap-2">
                <Button
                  variant={isInWatchlist ? 'secondary' : 'primary'}
                  onClick={() => addToWatchlist(item)}
                  disabled={isInWatchlist}
                  className="w-100"
                >
                  {isInWatchlist ? 'Already in Watchlist' : 'Add to Watchlist'}
                </Button>
                <Button
                  variant={isInFavorites ? 'secondary' : 'success'}
                  onClick={() => addToFavorites(item)}
                  disabled={isInFavorites}
                  className="w-100"
                >
                  {isInFavorites ? 'Already in Favorites' : 'Add to Favorites'}
                </Button>
              </div>
            </Card.Body>
          </Card>
          {index === items.length - 1 && (
            <div ref={lastItemRef} className="text-center mt-3">
              {isLoadingMore && <p>Loading more...</p>}
            </div>
          )}
        </Col>
      );
    });
  };

  return (
    <div className="container mt-4">
      <h1>
        Similar {activeTab === 'movie' ? 'Movies' : 'Shows'} to {currentTitle}
      </h1>
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => {
          setActiveTab(k);
          setSelectedGenre('All');
        }}
        className="mb-4"
      >
        <Tab eventKey="movie" title="Movies">
          {renderGenresButtons()}
          {renderYearsDropdown()}
          <Row>
            {renderItems(filterItemsBySelectedYear(filterItemsBySelectedGenre(similarMovies)))}
            {renderItems(filterItemsBySelectedYear(filterItemsBySelectedGenre(additionalMovies)))}
          </Row>
        </Tab>
        <Tab eventKey="tv" title="Shows">
          {renderGenresButtons()}
          {renderYearsDropdown()}
          <Row>
            {renderItems(filterItemsBySelectedYear(filterItemsBySelectedGenre(similarShows)))}
            {renderItems(filterItemsBySelectedYear(filterItemsBySelectedGenre(additionalShows)))}
          </Row>
        </Tab>
      </Tabs>

      {/* Back to Top Button */}
      <Button
        onClick={scrollToTop}
        style={{
          display: showScroll ? 'flex' : 'none',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: '#007bff',
          borderRadius: '50%',
          padding: '10px',
          zIndex: 1000,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FaArrowUp color="white" />
      </Button>

      {/* Modal for rating selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rate {selectedItem?.title || selectedItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StarRating rating={rating} onRatingChange={setRating} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveToFavorites}>
            Save to Favorites
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SimilarItemsPage;
