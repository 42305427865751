import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa';
import StarRating from '../components/StarRating';

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const AddMoviesShowsPage = () => {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('');
  const { user } = useAuth();
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(0);
  const [watchlist, setWatchlist] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [page, setPage] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const observer = useRef();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  useEffect(() => {
    const fetchResults = async () => {
      if (query.trim() === '') return;

      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/search/multi?api_key=${API_KEY}&query=${query}&page=${page}`
        );
        setResults((prevResults) => [...prevResults, ...response.data.results]);
      } catch (error) {
        console.error('Error fetching movies and shows:', error);
      }
    };

    fetchResults();
  }, [query, page]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;

      try {
        // Fetch watchlist data
        const userWatchlistRef = doc(db, 'watchlists', user.uid);
        const userWatchlistDoc = await getDoc(userWatchlistRef);
        if (userWatchlistDoc.exists()) {
          setWatchlist(userWatchlistDoc.data().movies || []);
        }

        // Fetch favorites data
        const userFavoritesRef = doc(db, 'favorites', user.uid);
        const userFavoritesDoc = await getDoc(userFavoritesRef);
        if (userFavoritesDoc.exists()) {
          setFavorites(userFavoritesDoc.data().items || []);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleSearch = (event) => {
    setQuery(event.target.value);
    setResults([]); // Clear previous results when a new search begins
    setPage(1); // Reset the page number
  };

  const handleClear = () => {
    setQuery('');
    setResults([]);
  };

  const addToFavorites = async (item) => {
    if (!user) {
      alert('Please log in to add items to your favorites.');
      return;
    }

    setSelectedItem(item);
    setShowModal(true);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const saveToFavorites = async () => {
    if (!user || !selectedItem) return;

    try {
      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      const itemWithRating = { ...selectedItem, rating };

      if (userFavoritesDoc.exists()) {
        await updateDoc(userFavoritesRef, {
          items: arrayUnion(itemWithRating),
          userName: user.displayName || 'Anonymous User',
        });
      } else {
        await setDoc(userFavoritesRef, {
          items: [itemWithRating],
          userName: user.displayName || 'Anonymous User',
        });
      }

      setFavorites((prevFavorites) => [...prevFavorites, itemWithRating]);

      alert(`${selectedItem.title || selectedItem.name} has been added to your favorites with a ${rating}-star rating!`);
      setShowModal(false);
      setRating(0);
    } catch (error) {
      console.error('Error adding item to favorites:', error);
      alert(`There was an issue adding the item to your favorites. ${error.message}`);
    }
  };

  const addToWatchlist = async (item) => {
    if (!user) {
      alert('Please log in to add items to your watchlist.');
      return;
    }

    try {
      const userWatchlistRef = doc(db, 'watchlists', user.uid);
      const userWatchlistDoc = await getDoc(userWatchlistRef);

      if (userWatchlistDoc.exists()) {
        await updateDoc(userWatchlistRef, {
          movies: arrayUnion(item),
        });
      } else {
        await setDoc(userWatchlistRef, {
          movies: [item],
        });
      }

      setWatchlist((prevWatchlist) => [...prevWatchlist, item]);

      alert(`${item.title || item.name} has been added to your watchlist!`);
    } catch (error) {
      console.error('Error adding item to watchlist:', error);
      alert(`There was an issue adding the item to your watchlist. ${error.message}`);
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const isInWatchlist = (item) => {
    return watchlist.some(
      (watchlistItem) =>
        watchlistItem.id === item.id && watchlistItem.media_type === item.media_type
    );
  };

  const isInFavorites = (item) => {
    return favorites.some(
      (favoriteItem) =>
        favoriteItem.id === item.id && favoriteItem.media_type === item.media_type
    );
  };

  const renderCardBody = (item) => {
    const releaseYear = item.release_date
      ? item.release_date.split('-')[0]
      : item.first_air_date
      ? item.first_air_date.split('-')[0]
      : 'Unknown';
  
    const briefDescription = item.overview
      ? item.overview.substring(0, 100) + '...'
      : 'No description available';
    const isExpanded = expandedDescriptions[item.id];
  
    const rating = item.vote_average !== undefined ? item.vote_average.toFixed(1) : 'N/A';
  
    return (
      <>
        <Card.Title>{item.title || item.name}</Card.Title>
        <Card.Text>Rating: {rating}</Card.Text>
        <Card.Text>Year: {releaseYear}</Card.Text>
        <div
          onClick={() => toggleDescription(item.id)}
          style={{
            border: '1px solid #ddd',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            marginBottom: '20px',
            cursor: 'pointer',
          }}
        >
          <p className="card-text">
            {isExpanded ? item.overview : briefDescription}
          </p>
          <Button
            variant="link"
            style={{ padding: '0', textDecoration: 'none', color: '#007bff' }}
          >
            {isExpanded ? 'View Less' : 'View More'}
          </Button>
        </div>
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            onClick={() => addToWatchlist(item)}
            disabled={!user || isInWatchlist(item)}
            title={user ? (isInWatchlist(item) ? 'Already in Watchlist' : 'Add to Watchlist') : 'Log in to add to Watchlist'}
            className="mt-2"
          >
            {user ? (isInWatchlist(item) ? 'Already in Watchlist' : 'Add to Watchlist') : 'Log in to Add to Watchlist'}
          </Button>
          <Button
            variant="success"
            onClick={() => addToFavorites(item)}
            disabled={!user || isInFavorites(item)}
            title={user ? (isInFavorites(item) ? 'Already in Favorites' : 'Add to Favorites') : 'Log in to add to Favorites'}
            className="mt-2"
          >
            {user ? (isInFavorites(item) ? 'Already in Favorites' : 'Add to Favorites') : 'Log in to Add to Favorites'}
          </Button>
        </div>
      </>
    );
  };

  const loadMoreResults = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const lastItemRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreResults();
        }
      });
      if (node) observer.current.observe(node);
    },
    []
  );

  return (
    <div>
      <div className="search-bar mt-2 p-3">
        <input
          type="text"
          placeholder="Search for a movie or show..."
          value={query}
          onChange={handleSearch}
          className="form-control"
        />
        <Button className="btn btn-secondary mt-2" onClick={handleClear}>
          Clear
        </Button>
      </div>
      <div className="container mt-4">
        <Row xs={1} md={2} lg={4} className="g-4">
          {results.map((item, index) => (
            <Col key={item.id} ref={index === results.length - 1 ? lastItemRef : null}>
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src={item.poster_path ? `https://image.tmdb.org/t/p/w500${item.poster_path}` : 'https://via.placeholder.com/150'}
                  alt={`${item.title || item.name} Poster`}
                />
                <Card.Body>{renderCardBody(item)}</Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      {/* Back to Top Button */}
      <Button
        onClick={scrollToTop}
        style={{
          display: showScroll ? 'flex' : 'none',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: '#007bff',
          borderRadius: '50%',
          padding: '10px',
          zIndex: 1000,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FaArrowUp color="white" />
      </Button>

      {/* Modal for rating selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rate {selectedItem?.title || selectedItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StarRating rating={rating} onRatingChange={handleRatingChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveToFavorites}>
            Save to Favorites
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddMoviesShowsPage;
