import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove, setDoc } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
import { Card, Button, Row, Col, Tabs, Tab, Dropdown, DropdownButton, Modal, Form } from 'react-bootstrap';
import StarRating from '../components/StarRating';

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const UserProfileFavorites = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [userFavorites, setUserFavorites] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [key, setKey] = useState('movies');
  const { user } = useAuth();
  const [matchesVisible, setMatchesVisible] = useState(false);
  const [commonItems, setCommonItems] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [userName, setUserName] = useState('');
  const [genres, setGenres] = useState({ movie: {}, tv: {} });
  const [selectedGenre, setSelectedGenre] = useState({ movie: 'All', tv: 'All' });
  const [selectedDecade, setSelectedDecade] = useState({ movie: 'All', tv: 'All' });
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [ratings, setRatings] = useState({});
  const [searchTerm, setSearchTerm] = useState(''); // Added searchTerm state
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(0);
  const [showBackToTop, setShowBackToTop] = useState(false); // Added for back-to-top button

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300); // Show button after scrolling down
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const removeDuplicates = (items) => {
    const uniqueItems = items.reduce((acc, currentItem) => {
      if (!acc.some(item => item.id === currentItem.id)) {
        acc.push(currentItem);
      }
      return acc;
    }, []);
    return uniqueItems;
  };

  const fetchFollowingStatus = useCallback(async () => {
    if (!user || !userId) return;

    try {
      const followsRef = doc(db, 'follows', user.uid);
      const followsDoc = await getDoc(followsRef);

      if (followsDoc.exists()) {
        const followsData = followsDoc.data();
        setIsFollowing(followsData.following.includes(userId));
      } else {
        setIsFollowing(false);
      }
    } catch (error) {
      console.error('Error fetching following status:', error);
    }
  }, [user, userId]);

  useEffect(() => {
    const fetchFavorites = async () => {
      const userFavoritesRef = doc(db, 'favorites', userId);
      const docSnap = await getDoc(userFavoritesRef);

      if (docSnap.exists()) {
        const userItems = removeDuplicates(docSnap.data().items.reverse());
        setFavorites(userItems);

        setUserName(docSnap.data().userName || 'User');

        if (user) {
          const currentUserFavoritesRef = doc(db, 'favorites', user.uid);
          const currentUserDocSnap = await getDoc(currentUserFavoritesRef);

          if (currentUserDocSnap.exists()) {
            const currentUserItems = removeDuplicates(currentUserDocSnap.data().items.reverse());
            setUserFavorites(currentUserItems);

            const currentUserItemsSet = new Set(currentUserItems.map((item) => item.id));
            const matches = userItems.filter((item) => currentUserItemsSet.has(item.id));

            setCommonItems(matches);

            // Initialize ratings state
            const initialRatings = userItems.reduce((acc, item) => {
              if (item.rating) acc[item.id] = item.rating;
              return acc;
            }, {});
            setRatings(initialRatings);
          }

          const watchlistRef = doc(db, 'watchlists', user.uid);
          const watchlistDocSnap = await getDoc(watchlistRef);

          if (watchlistDocSnap.exists()) {
            setWatchlist(removeDuplicates(watchlistDocSnap.data().movies.reverse() || []));
          }
        }
      } else {
        console.log('No such document!');
      }
    };

    fetchFavorites();
    fetchFollowingStatus();
  }, [userId, user, fetchFollowingStatus]);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const movieGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/movie/list?api_key=${API_KEY}`
        );
        const tvGenresResponse = await axios.get(
          `https://api.themoviedb.org/3/genre/tv/list?api_key=${API_KEY}`
        );

        setGenres({
          movie: movieGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
          tv: tvGenresResponse.data.genres.reduce((acc, genre) => {
            acc[genre.id] = genre.name;
            return acc;
          }, {}),
        });
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  const followUser = async () => {
    if (!user) {
      alert('Please log in to follow users.');
      return;
    }

    try {
      const followsRef = doc(db, 'follows', user.uid);
      const followsDoc = await getDoc(followsRef);

      if (followsDoc.exists()) {
        const followsData = followsDoc.data();
        if (followsData.following.includes(userId)) {
          await updateDoc(followsRef, {
            following: followsData.following.filter((id) => id !== userId),
          });
          setIsFollowing(false);
          alert(`Successfully unfollowed ${userName}`);
        } else {
          await updateDoc(followsRef, {
            following: arrayUnion(userId),
          });
          setIsFollowing(true);
          alert(`Successfully followed ${userName}`);
        }
      } else {
        await setDoc(followsRef, { following: [userId] });
        setIsFollowing(true);
        alert(`Successfully followed ${userName}`);
      }
    } catch (error) {
      console.error('Error following/unfollowing user:', error);
    }
  };

  const addToWatchlist = async (item) => {
    if (!user) {
      alert('Please log in to add items to your watchlist.');
      return;
    }

    try {
      const userWatchlistRef = doc(db, 'watchlists', user.uid);
      const userWatchlistDoc = await getDoc(userWatchlistRef);
      const currentWatchlist = userWatchlistDoc.exists() ? userWatchlistDoc.data().movies || [] : [];

      if (currentWatchlist.some(watchlistItem => watchlistItem.id === item.id)) {
        alert(`"${item.title || item.name}" is already in your watchlist.`);
        return;
      }

      await updateDoc(userWatchlistRef, {
        movies: arrayUnion(item),
      });

      setWatchlist([item, ...watchlist]);
      alert(`Added "${item.title || item.name}" to your watchlist!`);
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    }
  };

  const addToFavorites = async (item) => {
    if (!user) {
      alert('Please log in to add items to your favorites.');
      return;
    }

    setSelectedItem(item);
    setShowModal(true);
  };

  const handleRatingChange = async (newRating, item) => {
    if (!user || user.uid !== userId) return; // Prevent rating change for other profiles

    try {
      const updatedItem = { ...item, rating: newRating };
      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);

      if (userFavoritesDoc.exists()) {
        const items = userFavoritesDoc.data().items || [];
        const updatedItems = items.map((favItem) =>
          favItem.id === item.id ? updatedItem : favItem
        );
        await updateDoc(userFavoritesRef, { items: updatedItems });

        setRatings((prevRatings) => ({
          ...prevRatings,
          [item.id]: newRating,
        }));
      }
    } catch (error) {
      console.error('Error updating rating:', error);
    }
  };

  const saveToFavorites = async () => {
    if (!user || !selectedItem) return;

    try {
      const userFavoritesRef = doc(db, 'favorites', user.uid);
      const userFavoritesDoc = await getDoc(userFavoritesRef);
      const itemWithRating = { ...selectedItem, rating };

      if (userFavoritesDoc.exists()) {
        await updateDoc(userFavoritesRef, {
          items: arrayUnion(itemWithRating),
        });
        setUserFavorites([itemWithRating, ...userFavorites]);
      } else {
        await setDoc(userFavoritesRef, { items: [itemWithRating] });
        setUserFavorites([itemWithRating]);
      }

      alert(`${selectedItem.title || selectedItem.name} has been added to your favorites with a ${rating}-star rating!`);
      setShowModal(false);
      setRating(0);
    } catch (error) {
      console.error('Error adding item to favorites:', error);
      alert(`There was an issue adding the item to your favorites. ${error.message}`);
    }
  };

  const removeFromFavorites = async (item) => {
    if (!user) {
      alert('Please log in to remove items from your favorites.');
      return;
    }

    try {
      const userFavoritesRef = doc(db, 'favorites', user.uid);

      await updateDoc(userFavoritesRef, {
        items: arrayRemove(item),
      });

      setUserFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== item.id)
      );
      setFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== item.id)
      );
      alert(`Removed "${item.title || item.name}" from your favorites!`);
    } catch (error) {
      console.error('Error removing from favorites:', error);
    }
  };

  const filterItemsBySelectedGenre = (items, type) => {
    if (selectedGenre[type] === 'All') return items;

    return items.filter((item) => {
      const itemGenres = item.genre_ids.map((id) => genres[item.media_type][id]);
      return itemGenres.includes(selectedGenre[type]);
    });
  };

  const handleGenreSelect = (genre, type) => {
    setSelectedGenre((prev) => ({
      ...prev,
      [type]: prev[type] === genre ? 'All' : genre,
    }));
  };

  const handleDecadeSelect = (decade, type) => {
    setSelectedDecade((prev) => ({
      ...prev,
      [type]: prev[type] === decade ? 'All' : decade,
    }));
  };

  const renderGenresButtons = (type) => {
    const uniqueGenres = Array.from(
      new Set(
        filterItems(type, favorites)
          .flatMap((item) => item.genre_ids.map((id) => genres[type][id]))
          .filter((genre) => genre !== undefined)
      )
    );

    return (
      <div className="d-flex flex-nowrap overflow-auto mb-3">
        <Button
          variant={selectedGenre[type] === 'All' ? 'primary' : 'light'}
          className="me-2"
          onClick={() => handleGenreSelect('All', type)}
        >
          All
        </Button>
        {uniqueGenres.map((genre, index) => (
          <Button
            key={index}
            variant={selectedGenre[type] === genre ? 'primary' : 'light'}
            className="me-2"
            onClick={() => handleGenreSelect(genre, type)}
          >
            {genre}
          </Button>
        ))}
      </div>
    );
  };

  const renderYearsDropdown = (type) => {
    const items = filterItems(type, matchesVisible ? commonItems : favorites);
    const years = items
      .map((item) => {
        const year = item.release_date
          ? item.release_date.split('-')[0]
          : item.first_air_date
          ? item.first_air_date.split('-')[0]
          : null;
        return parseInt(year, 10);
      })
      .filter((year) => !isNaN(year));

    const uniqueYears = Array.from(new Set(years.map((year) => Math.floor(year / 10) * 10)));

    const currentYear = selectedDecade[type];
    const title = currentYear === 'All' ? 'Years: All' : `Years: ${currentYear}-${currentYear + 9}`;

    return (
      <DropdownButton
        id={`dropdown-year-${type}`}
        title={title}
        variant="secondary"
        onSelect={(e) => handleDecadeSelect(e === 'All' ? 'All' : parseInt(e, 10), type)}
      >
        <Dropdown.Item eventKey="All" active={selectedDecade[type] === 'All'}>
          All
        </Dropdown.Item>
        {uniqueYears.sort((a, b) => b - a).map((decade, index) => (
          <Dropdown.Item
            key={index}
            eventKey={decade}
            active={selectedDecade[type] === decade}
          >
            {`${decade}-${decade + 9}`}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };

  const filterItems = (type, items) => {
    return items.filter((item) => item.media_type === type);
  };

  const filterItemsBySelectedDecade = (items, type) => {
    if (selectedDecade[type] === 'All') return items;

    return items.filter((item) => {
      const year = item.release_date
        ? item.release_date.split('-')[0]
        : item.first_air_date
        ? item.first_air_date.split('-')[0]
        : null;

      return year && Math.floor(year / 10) * 10 === selectedDecade[type];
    });
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleViewSimilar = (mediaType, itemId) => {
    navigate(`/similar/${mediaType}/${itemId}`);
  };

  const renderCardBody = (item) => {
    const releaseYear = item.release_date
      ? item.release_date.split('-')[0]
      : item.first_air_date
      ? item.first_air_date.split('-')[0]
      : 'Unknown';

    const isInWatchlist = watchlist.some(watchlistItem => watchlistItem.id === item.id);
    const isInFavorites = userFavorites.some(favoriteItem => favoriteItem.id === item.id);

    const briefDescription = item.overview
      ? item.overview.substring(0, 100) + '...'
      : 'No description available';
    const isExpanded = expandedDescriptions[item.id];

    return (
      <>
        <Card.Title>{item.title || item.name}</Card.Title>
        <Card.Text>Rating: {item.vote_average}</Card.Text>
        <Card.Text>Year: {releaseYear}</Card.Text>
        <StarRating
          rating={ratings[item.id] || 0}
          onRatingChange={(newRating) => handleRatingChange(newRating, item)}
          readOnly={!user || user.uid !== userId} // Allow rating change only for the user's own profile
        />
        <div
          onClick={() => toggleDescription(item.id)}
          style={{
            border: '1px solid #ddd',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            marginBottom: '20px',
            cursor: 'pointer',
          }}
        >
          <p className="card-text">
            {isExpanded ? item.overview : briefDescription}
          </p>
          <Button
            variant="link"
            style={{ padding: '0', textDecoration: 'none', color: '#007bff' }}
          >
            {isExpanded ? 'View Less' : 'View More'}
          </Button>
        </div>
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            onClick={() => addToWatchlist(item)}
            disabled={!user || isInWatchlist}
            title={
              !user
                ? 'Please log in to add items to your watchlist.'
                : isInWatchlist
                ? `"${item.title || item.name}" is already in your watchlist.`
                : 'Add to Watchlist'
            }
            className={user ? 'd-block d-md-inline' : ''} // Stack on mobile when logged in
          >
            {user
              ? isInWatchlist
                ? 'Already in Watchlist'
                : 'Add to Watchlist'
              : 'Log in to Add to Watchlist'}
          </Button>
          {user && user.uid === userId ? (
            <Button
              variant="danger"
              onClick={() => removeFromFavorites(item)}
              disabled={!user}
              title={
                !user
                  ? 'Please log in to remove items from your favorites.'
                  : 'Remove from Favorites'
              }
              className={`mt-2 ${user ? 'd-block d-md-inline' : ''}`} // Stack on mobile when logged in
            >
              Remove from Favorites
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={() => addToFavorites(item)}
              disabled={!user || user.uid === userId || isInFavorites} // Disable if not logged in or if viewing own profile
              title={
                !user
                  ? 'Please log in to add items to your favorites.'
                  : user.uid === userId
                  ? 'You cannot add to your own favorites.'
                  : isInFavorites
                  ? `"${item.title || item.name}" is already in your favorites.`
                  : 'Add to Favorites'
              }
              className={`mt-2 ${user ? 'd-block d-md-inline' : ''}`} // Stack on mobile when logged in
            >
              {user
                ? user.uid === userId
                  ? 'Cannot Add to Own Favorites'
                  : isInFavorites
                  ? 'Already in Favorites'
                  : 'Add to Favorites'
                : 'Log in to Add to Favorites'}
            </Button>
          )}
          <Button
            variant="info"
            onClick={() => handleViewSimilar(item.media_type, item.id)}
            className="mt-2"
          >
            Similar Movies & Shows
          </Button>
        </div>
      </>
    );
  };

  const toggleMatchesView = () => {
    setMatchesVisible(!matchesVisible);
  };

  const filteredItems = (items) => {
    return items.filter((item) =>
      (item.title || item.name).toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="container mt-4">
      <h1>{userName}'s Favorites</h1>
      {user && user.uid !== userId && (
        <div className="mb-3 d-flex justify-content-between">
          <button
            className={`btn ${
              isFollowing ? 'btn-primary text-white' : 'btn-light text-primary'
            }`}
            onClick={followUser}
            disabled={!user}
          >
            {isFollowing ? 'Unfollow' : 'Follow'}
          </button>
          <button
            className={`btn ${
              matchesVisible ? 'btn-primary' : 'btn-outline-secondary'
            }`}
            onClick={toggleMatchesView}
          >
            {matchesVisible ? 'Hide Matches' : 'Show Matches'}
            <span className="badge bg-danger ms-2">
              {commonItems.length > 100 ? '100+' : commonItems.length}
            </span>
          </button>
        </div>
      )}
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="movies" title="Movies">
          <div className="mb-3">{renderGenresButtons('movie')}</div>
          <div className="d-flex justify-content-start mb-3">
            {renderYearsDropdown('movie')}
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Form.Control
              type="text"
              placeholder="Search Favorites"
              style={{ maxWidth: '300px' }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              variant="secondary"
              className="ms-2"
              onClick={clearSearch}
            >
              Clear
            </Button>
          </div>
          <Row xs={1} md={2} lg={4} className="g-4">
            {filterItemsBySelectedDecade(
              filterItemsBySelectedGenre(
                filteredItems(
                  filterItems('movie', matchesVisible ? commonItems : favorites)
                ),
                'movie'
              ),
              'movie'
            ).map((item) => (
              <Col key={item.id}>
                <Card className="h-100">
                  <Card.Img
                    variant="top"
                    src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                    alt={`${item.title} Poster`}
                  />
                  <Card.Body>{renderCardBody(item)}</Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Tab>
        <Tab eventKey="shows" title="Shows">
          <div className="mb-3">{renderGenresButtons('tv')}</div>
          <div className="d-flex justify-content-start mb-3">
            {renderYearsDropdown('tv')}
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <Form.Control
              type="text"
              placeholder="Search Favorites"
              style={{ maxWidth: '300px' }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              variant="secondary"
              className="ms-2"
              onClick={clearSearch}
            >
              Clear
            </Button>
          </div>
          <Row xs={1} md={2} lg={4} className="g-4">
            {filterItemsBySelectedDecade(
              filterItemsBySelectedGenre(
                filteredItems(
                  filterItems('tv', matchesVisible ? commonItems : favorites)
                ),
                'tv'
              ),
              'tv'
            ).map((item) => (
              <Col key={item.id}>
                <Card className="h-100">
                  <Card.Img
                    variant="top"
                    src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                    alt={`${item.name} Poster`}
                  />
                  <Card.Body>{renderCardBody(item)}</Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Tab>
      </Tabs>

      {/* Back to Top Button */}
      {showBackToTop && (
        <button
          onClick={handleBackToTop}
          className="btn btn-primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            borderRadius: '50%',
            fontSize: '20px',
            color: 'white',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      )}

      {/* Modal for rating selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rate {selectedItem?.title || selectedItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StarRating
            rating={rating}
            onRatingChange={(newRating) => setRating(newRating)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveToFavorites}>
            Save to Favorites
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserProfileFavorites;
